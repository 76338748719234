import React, { useEffect, useState } from 'react'
import { OptimizeBlockConstants, ImageContenfullConstants } from '../../adapters/helpers/Constants';
import ImageFactory from '../../adapters/cloudinary/ImageFactory';
import { mediaQueryHOC } from '../../adapters/helpers/Hooks';
import { optimizeImage } from '../../adapters/helpers/pagespeedHelper';

function OptimizeBlock(props) {
    const [visibility, setVisibility] = useState(true);
    const [showStatus, setShowStatus] = useState(false);
    const optimizeContent = props?.document?.fields;
    const mobileBackgroundAsset = props?.document?.fields?.mobileBackgroundAsset;
    const backgroundAsset = props?.document?.fields?.backgroundAsset;
    const isMedium = props?.isMedium;

    useEffect(() => {
        setTimeout(function() {
            setShowStatus(true);
        }, 10000)
    }, []);

    return (
        <>
            {(optimizeContent && showStatus) ?
                <div id="Shop_Nu" aria-label="Virtual Assistant" aria-hidden="false" role="complementary" className={`ob-app-store ${visibility ? 'display-block' : 'hidden-block'}`}>
                    <a href={optimizeContent.callToActionLink} className='ob-app-store__link event_outbound_link' target="_blank" rel="noopener noreferrer" data-action-detail={optimizeContent.callToActionLink}>
                        {!isMedium && <img className='ob-app-store__selected-image-img'
                            alt={backgroundAsset.fields.alternateText || OptimizeBlockConstants.empty}
                            src={optimizeImage(ImageFactory.imageContentfullDpr(backgroundAsset?.fields?.asset?.fields?.file?.url,ImageContenfullConstants.dpr_398))}
                            style={{ maxHeight: backgroundAsset.fields.assetHeight ? `${backgroundAsset.fields.assetHeight}px` : '711px' }}
                        />}
                        {isMedium && <img className='ob-app-store__selected-image-img'
                            alt={backgroundAsset.fields.alternateText || OptimizeBlockConstants.empty}
                            src={optimizeImage(ImageFactory.imageContentfullDpr(mobileBackgroundAsset?.fields?.asset?.fields?.file?.url, ImageContenfullConstants.dpr_398))}
                            style={{ maxHeight: backgroundAsset.fields.assetHeight ? `${backgroundAsset.fields.assetHeight}px` : '711px' }}
                    />}
                    </a>
                    <div role = "button" tabIndex={0} id="close_ShopNu" aria-label='close' className='ob-app-store__close-button event_button_click' onClick={() => setVisibility(false)}  onKeyDown={() => setVisibility(false)}>
                        <span>&nbsp;</span>
                    </div>  
                </div>
                : null
            }
        </>
    )
}

export default mediaQueryHOC(OptimizeBlock);

